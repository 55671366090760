<template>
  <CSidebar 
    :fixed="true"
    :minimize="minimize"
    :show="show"
    dropdownMode="closeInactive"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <!-- <div class="app-logo">
      <router-link to="/">
        <img src="@/assets/metabetall.png">
      </router-link>
    </div> -->
    <AgentProfile />
    <CRenderFunction flat :content-to-render="nav"/>
  </CSidebar>
</template>

<script>
import nav from './_nav'
import AgentProfile from './AgentProfile'

export default {
  name: 'TheSidebar',
  components: {
    AgentProfile
  },
  //nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    },
    role() {
      return this.$store.state.userProfile.role
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    userProfile() {
      return this.$store.state.userProfile
    },
    globalMarket() {
      return this.$store.state.globalMarket
    },
    permission() {
      return this.$store.state.account?.permission || {}
    },
    nav() {
      let setNav = JSON.parse(JSON.stringify(nav))

      setNav[0]._children = setNav[0]._children.filter((_children)=>{
        const accountRoles = _children?.roles || [this.role]
        const userRoles = _children?.userRoles || [this.userRole]

        /**
         * ตรวจสอบสิทธิ
         */
        let allowPermission = false
        if(this.userRole === 'Owner') {
          allowPermission = true
        } else
        if(this.userRole) {
          const navName = _children?.permissionName || 'none'
          const assPermission = this.permission?.[navName] ?? 'r'
          allowPermission = assPermission !== 'h'
        }

        return accountRoles.includes(this.role) && userRoles.includes(this.userRole) && allowPermission
      })
      .map((_children)=>{

        _children.items = _children.items.filter((item)=>{

          if(item.to === '/manage-account/create/agent' && !this.userProfile.canAddAgent)
            return false

          const accountRoles = item?.roles || [this.role]
          const userRoles = item?.userRoles || [this.userRole]

          /**
           * ตรวจสอบสิทธิ
           */
          let allowPermission = false
          if(this.userRole === 'Owner') {
            allowPermission = true
          } else
          if(this.userRole) {
            const navName = item?.permissionName || 'none'
            const assPermission = this.permission?.[navName] ?? 'r'
            allowPermission = assPermission !== 'h'
          }

          return accountRoles.includes(this.role) && userRoles.includes(this.userRole) && allowPermission
        })
        .filter((item)=>{

          if(!item?.mTypes)
            return true

          return item.mTypes.includes(this.globalMarket.marketType)
        })

        return _children
      })

      return setNav
    }
  }
}
</script>
<style lang="scss">
.c-sidebar {
  background: #0A2647;
  // min-height: 1200px;

  ul {
    .c-sidebar-nav-dropdown.c-show {
      a.c-sidebar-nav-dropdown-toggle {
        background-color: #205295;
      }
      ul {
        background-color: #0A2647;
      }
    }
  }
}
</style>
