<template>
  <CHeaderNavItem>
    <span class="header-time">{{datetime}}</span>
  </CHeaderNavItem>
</template>
<script>
import moment from '@/helpers/moment'

export default {
  data() {
    return {
      datetime: moment().add(543, 'y').format('D MMMM YYYY, HH:mm:ss'),
      TimeInterval: null
    }
  },
  mounted() {
    this.TimeInterval = setInterval(()=>{
      this.datetime = moment().add(543, 'y').format('D MMMM YYYY, HH:mm:ss')
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.TimeInterval)
  }
}
</script>
<style lang="scss" scoped>
.header-time {
  color: #EEEEEE;
  // font-size: 85%;
  font-weight: normal;
}
</style>
