<template>
  <CDropdown
    inNav
    class="c-header-nav-items global-market"
    placement="bottom-end"
    add-menu-classes="p-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <img v-if="marketIcon" :src="marketIcon" style="width: 40px; border-radius: 5px;" class="mr-2">
        <span>{{marketTitle}}</span>
        <i class="fas fa-caret-down ml-2"></i>
      </CHeaderNavLink>
    </template>
    <div v-for="group in groups">
      <CDropdownHeader tag="div" class="text-center alert-header p-1">
        <strong>{{ group.groupTitle }}</strong>
      </CDropdownHeader>
      <div class="market-items row">
        <div class="col-3" v-for="market in group.markets" :key="market._id">
          <CDropdownItem @click="toggleMarket(market)">
            <img :src="market.imageIcon" style="width: 30px; height: 20px; border-radius: 2px;" class="mr-2"> {{market.marketTitle}}
          </CDropdownItem>
        </div>
      </div>
    </div>
  </CDropdown>
</template>
<script>
export default {
  computed: {
    markets() {
      return this.$store.state?.markets || []
    },
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        return group.status === 'Active'
      })
      .map((group)=>{
        group.markets = this.markets.filter((market)=>{
          return group._id === market.groupId && market.status === 'Open'
        })
        return group
      })
    },
    globalMarket() {
      return this.$store.state.globalMarket
    },
    currentMarketId() {
      return this.globalMarket.marketId
    },
    marketTitle() {
      const market = this.$store.state.markets.find(market=>market._id===this.currentMarketId)
      return market?.marketTitle
    },
    marketIcon() {
      return this.globalMarket?.imageIcon
    }
  },
  methods: {
    toggleMarket(market) {
      this.$store.commit('updateGlobalMarket', {
        groupId: market.groupId,
        marketId: market._id,
        marketType: market.marketType,
        imageIcon: market.imageIcon
      })
    }
  }
}
</script>
<style lang="scss">
.global-market {
  .dropdown-menu {
    width: 850px;

    .market-items {
      display: flex;

      .dropdown-item {
        padding: 0.2rem 0.5rem;
      }
    }
  }
}
</style>
