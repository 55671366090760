<template>
  <CDropdown
    inNav
    class="c-header-nav-items global-round"
    placement="bottom-end"
    add-menu-classes="p-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <i class="far fa-calendar-alt mr-1"></i>
        <span class="mr-2">งวดวันที่</span>
        <span>{{ roundDate | dateFormat}}</span>
        <i class="fas fa-caret-down ml-2"></i>
      </CHeaderNavLink>
    </template>
    <CDropdownItem v-for="item in items" :key="item._id" @click="selectRound(item)">
      {{item.roundDate.date | dateFormat}} <span class="badge font-weight-normal" :class="item.cssClass.badge">{{ item.cssClass.text }}</span>
    </CDropdownItem>
  </CDropdown>
</template>
<script>
import MarketService from '@/services/MarketService'
import moment from '@/helpers/moment'

export default {
  data() {
    return {
      rounds: []
    }
  },
  computed: {
    globalMarket() {
      return this.$store.state.globalMarket
    },
    currentMarketId() {
      return this.$store.state.globalMarket.marketId
    },
    currentRoundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    items() {
      return this.rounds.map((round)=>{

        const cssClass = {
          badge: 'badge-secondary',
          text: ''
        }

        if(round.status === 'Open') {
          if(moment().isBefore(round.roundDate.open)) {
            cssClass.badge = 'badge-warning'
            cssClass.text = 'รอเปิดแทง'
          }else
          if(moment().isBefore(round.roundDate.close)) {
            cssClass.badge = 'badge-success'
            cssClass.text = 'เปิดรับแทง'
          }else{
            cssClass.badge = 'badge-warning'
            cssClass.text = 'ปิดรับแทง'
          }
        }else
        if(round.status === 'Close') {
          cssClass.badge = 'badge-warning'
          cssClass.text = 'ปิดรับแทง'
        }else
        if(round.status === 'Cancel') {
          cssClass.badge = 'badge-danger'
          cssClass.text = 'ยกเลิก'
        }else
        if(round.status === 'Refund') {
          cssClass.badge = 'badge-danger'
          cssClass.text = 'คืนเงิน'
        }else
        if(round.status === 'Complete') {
          cssClass.badge = 'badge-info'
          cssClass.text = 'กำลังออกผล'
        }else
        if(round.status === 'Paid') {
          cssClass.badge = 'badge-primary'
          cssClass.text = 'จ่ายเงินแล้ว'
        }

        if(this.currentRoundId === round._id) {
          cssClass.card = 'alert-primary round-active'
        }

        return {
          ...round,
          cssClass: cssClass
        }
      })
    },
    roundDate() {
      return this.globalMarket?.round?.roundDate?.date
    },
  },
  watch: {
    currentMarketId() {
      if(this.currentMarketId){
        this.loadRounds()
      }
    }
  },
  methods: {
    loadRounds() {
      this.rounds = []
      MarketService.getRounds(this.currentMarketId)
      .then((response)=>{
        if(response.success) {
          this.rounds = response.data
        }
      })
    },
    selectRound(round) {
      this.$store.commit('updateGlobalMarket', {
        round: {
          roundId: round._id,
          roundDate: round.roundDate
        }
      })
    }
  },
  mounted() {
    if(this.currentMarketId){
      this.loadRounds()
    }
  },
  filters: {
    dateFormat(date) {
      if(!date)
        return '-'

      return moment(date).format("DD-MM-YYYY")
    }
  }
}
</script>
<style lang="scss">
.global-round {
  .dropdown-menu {
    .dropdown-item {
      min-width: 220px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
